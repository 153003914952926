import React from 'react';
import { Helmet } from 'react-helmet';

import Accordion from '../../components/Accordion/Accordion';
import Box from '../../components/Box/Box';
import DownloadList from '../../components/DownloadList/DownloadList';
import Headline from '../../components/Headline/Headline';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import { Layout } from '../../components/Layout/Layout';
import { Loading } from '../../components/Loading';
import { ContentElements } from '../../interfaces/ContentElements';
import { DownloadLink } from '../../interfaces/downloadLink';
import CmsManager from '../../services/manager/CmsManager';
import StateManager from '../../services/manager/StateManager';

interface Props {}

interface FaqItem {
  title: string | DownloadLink[];
  content: string | DownloadLink[];
}

interface State {
  loading: boolean;
  content?: ContentElements;
  faqGeneral?: ContentElements;
  faqGeneralItems: FaqItem[];
  faqRegistration?: ContentElements;
  faqRegistrationItems: FaqItem[];
  faqCashback?: ContentElements;
  faqCashbackItems: FaqItem[];
  faqTueroeffnungsservice?: ContentElements;
  faqTueroeffnungsserviceItems: FaqItem[];
  faqSchluesselfundservice?: ContentElements;
  faqSchluesselfundserviceItems: FaqItem[];
  faqKartenunddokumentenservice?: ContentElements;
  faqKartenunddokumentenserviceItems: FaqItem[];
  faqReiseservice?: ContentElements;
  faqReiseserviceItems: FaqItem[];
  faqTicketsevice?: ContentElements;
  faqTicketseviceItems: FaqItem[];
  faqNotfallbargeld?: ContentElements;
  faqNotfallbargeldItems: FaqItem[];
  faq24hhotline?: ContentElements;
  faq24hhotlineItems: FaqItem[];
  faqGutscheine?: ContentElements;
  faqGutscheineItems: FaqItem[];
}

export default class PlusServices extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      faqGeneralItems: [],
      faqRegistrationItems: [],
      faqCashbackItems: [],
      faqTueroeffnungsserviceItems: [],
      faqSchluesselfundserviceItems: [],
      faqKartenunddokumentenserviceItems: [],
      faqReiseserviceItems: [],
      faqTicketseviceItems: [],
      faqNotfallbargeldItems: [],
      faq24hhotlineItems: [],
      faqGutscheineItems: [],
    };
  }

  async componentDidMount() {
    StateManager.resetProductsFilter();
    StateManager.resetShopFilter();

    const contents = await Promise.all([
      CmsManager.getPageContentsCleaned('plus-leistungen'),
      CmsManager.getPageContentsCleaned('faq-allgemein'),
      CmsManager.getPageContentsCleaned('faq-anmeldung'),
      CmsManager.getPageContentsCleaned('faq-cashback'),
      CmsManager.getPageContentsCleaned('faq-turoffnungsservice'),
      CmsManager.getPageContentsCleaned('faq-schlusselfundservice'),
      CmsManager.getPageContentsCleaned('faq-karten-und-dokumentenschutz'),
      CmsManager.getPageContentsCleaned('faq-reiseservice'),
      CmsManager.getPageContentsCleaned('faq-ticketservice'),
      CmsManager.getPageContentsCleaned('faq-notfallbargeld'),
      CmsManager.getPageContentsCleaned('faq-24h-hotline'),
      CmsManager.getPageContentsCleaned('faq-gutscheine'),
    ]);

    contents.forEach((page, index) => {
      if (index !== 0) {
        Object.keys(page).forEach((item: string) => {
          if (
            item !== 'pageTitle' &&
            item !== 'pageDescription' &&
            item.endsWith('Title') &&
            typeof item === 'string'
          ) {
            switch (index) {
              case 1:
                const tempGeneralItems = this.state.faqGeneralItems;

                this.setState({
                  faqGeneralItems: [
                    ...tempGeneralItems,
                    {
                      title: page[item] as string,
                      content: page[item.slice(0, -5)] as string,
                    },
                  ],
                });
                break;
              case 2:
                const tempRegistrationItems = this.state.faqRegistrationItems;

                this.setState({
                  faqRegistrationItems: [
                    ...tempRegistrationItems,
                    {
                      title: page[item] as string,
                      content: page[item.slice(0, -5)] as string,
                    },
                  ],
                });
                break;
              case 3:
                const tempCashbackItems = this.state.faqCashbackItems;

                this.setState({
                  faqCashbackItems: [
                    ...tempCashbackItems,
                    {
                      title: page[item] as string,
                      content: page[item.slice(0, -5)] as string,
                    },
                  ],
                });
                break;
              case 4:
                const tempTueroeffnungsserviceItems = this.state
                  .faqTueroeffnungsserviceItems;

                this.setState({
                  faqTueroeffnungsserviceItems: [
                    ...tempTueroeffnungsserviceItems,
                    {
                      title: page[item] as string,
                      content: page[item.slice(0, -5)] as string,
                    },
                  ],
                });
                break;
              case 5:
                const tempSchluesselfundserviceItems = this.state
                  .faqSchluesselfundserviceItems;

                this.setState({
                  faqSchluesselfundserviceItems: [
                    ...tempSchluesselfundserviceItems,
                    {
                      title: page[item] as string,
                      content: page[item.slice(0, -5)] as string,
                    },
                  ],
                });
                break;
              case 6:
                const tempKartenunddokumentenserviceItems = this.state
                  .faqKartenunddokumentenserviceItems;

                this.setState({
                  faqKartenunddokumentenserviceItems: [
                    ...tempKartenunddokumentenserviceItems,
                    {
                      title: page[item] as string,
                      content: page[item.slice(0, -5)] as string,
                    },
                  ],
                });
                break;
              case 7:
                const tempReiseserviceItems = this.state.faqReiseserviceItems;

                this.setState({
                  faqReiseserviceItems: [
                    ...tempReiseserviceItems,
                    {
                      title: page[item] as string,
                      content: page[item.slice(0, -5)] as string,
                    },
                  ],
                });
                break;
              case 8:
                const tempTicketserviceItems = this.state.faqTicketseviceItems;

                this.setState({
                  faqTicketseviceItems: [
                    ...tempTicketserviceItems,
                    {
                      title: page[item] as string,
                      content: page[item.slice(0, -5)] as string,
                    },
                  ],
                });
                break;
              case 9:
                const tempNotfallbargeldItems = this.state
                  .faqNotfallbargeldItems;

                this.setState({
                  faqNotfallbargeldItems: [
                    ...tempNotfallbargeldItems,
                    {
                      title: page[item] as string,
                      content: page[item.slice(0, -5)] as string,
                    },
                  ],
                });
                break;
              case 10:
                const temp24hhotlineItems = this.state.faq24hhotlineItems;

                this.setState({
                  faq24hhotlineItems: [
                    ...temp24hhotlineItems,
                    {
                      title: page[item] as string,
                      content: page[item.slice(0, -5)] as string,
                    },
                  ],
                });
                break;
              case 11:
                const tempGutscheineItems = this.state.faqGutscheineItems;

                this.setState({
                  faqGutscheineItems: [
                    ...tempGutscheineItems,
                    {
                      title: page[item] as string,
                      content: page[item.slice(0, -5)] as string,
                    },
                  ],
                });
                break;
            }
          }

          return true;
        });
      }
    });

    this.setState({
      loading: false,
      content: contents[0],
      faqGeneral: contents[1],
      faqRegistration: contents[2],
      faqCashback: contents[3],
      faqTueroeffnungsservice: contents[4],
      faqSchluesselfundservice: contents[5],
      faqKartenunddokumentenservice: contents[6],
      faqReiseservice: contents[7],
      faqTicketsevice: contents[8],
      faqNotfallbargeld: contents[9],
      faq24hhotline: contents[10],
      faqGutscheine: contents[11],
    });
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Plusleistungen - OLBplus Vorteilsportal</title>
        </Helmet>
        <div className="PlusServices">
          {this.state.loading && (
            <InnerLayout>
              <Loading />
            </InnerLayout>
          )}

          {!this.state.loading && this.state.content && (
            <div>
              <InnerLayout>
                {this.state.content.serviceTitle && (
                  <Box>
                    <Headline type="h1">
                      {this.state.content.serviceTitle}
                    </Headline>

                    {this.state.content.service && (
                      <div style={{ width: '80%' }}>
                        <Headline type="h2" color="secondary" styling="thin">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: this.state.content.service as string,
                            }}
                          ></p>
                        </Headline>
                      </div>
                    )}
                  </Box>
                )}

                {this.state.content.downloadlinksTitle &&
                  this.state.content.downloadlinksLinks &&
                  this.state.content.downloadlinksLinks.length > 0 && (
                    <Box>
                      <Headline type="h2">
                        {this.state.content.downloadlinksTitle}
                      </Headline>

                      <DownloadList
                        links={
                          this.state.content
                            .downloadlinksLinks as DownloadLink[]
                        }
                      ></DownloadList>
                    </Box>
                  )}

                {this.state.faqGeneral && this.state.faqGeneralItems && (
                  <Box>
                    <Headline type="h2">
                      {this.state.faqGeneral.pageTitle}
                    </Headline>

                    <Accordion items={this.state.faqGeneralItems}></Accordion>
                  </Box>
                )}

                {this.state.faqRegistration && this.state.faqRegistrationItems && (
                  <Box>
                    <Headline type="h2">
                      {this.state.faqRegistration.pageTitle}
                    </Headline>

                    <Accordion
                      items={this.state.faqRegistrationItems}
                    ></Accordion>
                  </Box>
                )}

                {this.state.faqCashback && this.state.faqCashbackItems && (
                  <Box>
                    <Headline type="h2">
                      {this.state.faqCashback.pageTitle}
                    </Headline>

                    <Accordion items={this.state.faqCashbackItems}></Accordion>
                  </Box>
                )}

                {this.state?.faqGutscheine && this.state?.faqGutscheineItems && (
                  <Box>
                    <Headline type="h2">
                      {this.state?.faqGutscheine?.pageTitle}
                    </Headline>

                    <Accordion
                      items={this.state.faqGutscheineItems}
                    ></Accordion>
                  </Box>
                )}

                {this.state.faqTueroeffnungsservice &&
                  this.state.faqTueroeffnungsserviceItems && (
                    <Box>
                      <Headline type="h2">
                        {this.state.faqTueroeffnungsservice.pageTitle}
                      </Headline>

                      <Accordion
                        items={this.state.faqTueroeffnungsserviceItems}
                      ></Accordion>
                    </Box>
                  )}

                {this.state.faqSchluesselfundservice &&
                  this.state.faqSchluesselfundserviceItems && (
                    <Box>
                      <Headline type="h2">
                        {this.state.faqSchluesselfundservice.pageTitle}
                      </Headline>

                      <Accordion
                        items={this.state.faqSchluesselfundserviceItems}
                      ></Accordion>
                    </Box>
                  )}

                {this.state.faqKartenunddokumentenservice &&
                  this.state.faqKartenunddokumentenserviceItems && (
                    <Box>
                      <Headline type="h2">
                        {this.state.faqKartenunddokumentenservice.pageTitle}
                      </Headline>

                      <Accordion
                        items={this.state.faqKartenunddokumentenserviceItems}
                      ></Accordion>
                    </Box>
                  )}

                {this.state.faqReiseservice && this.state.faqReiseserviceItems && (
                  <Box>
                    <Headline type="h2">
                      {this.state.faqReiseservice.pageTitle}
                    </Headline>

                    <Accordion
                      items={this.state.faqReiseserviceItems}
                    ></Accordion>
                  </Box>
                )}

                {this.state.faqTicketsevice && this.state.faqTicketseviceItems && (
                  <Box>
                    <Headline type="h2">
                      {this.state.faqTicketsevice.pageTitle}
                    </Headline>

                    <Accordion
                      items={this.state.faqTicketseviceItems}
                    ></Accordion>
                  </Box>
                )}

                {this.state.faqNotfallbargeld &&
                  this.state.faqNotfallbargeldItems && (
                    <Box>
                      <Headline type="h2">
                        {this.state.faqNotfallbargeld.pageTitle}
                      </Headline>

                      <Accordion
                        items={this.state.faqNotfallbargeldItems}
                      ></Accordion>
                    </Box>
                  )}

                {this.state.faq24hhotline && this.state.faq24hhotlineItems && (
                  <Box>
                    <Headline type="h2">
                      {this.state.faq24hhotline.pageTitle}
                    </Headline>

                    <Accordion
                      items={this.state.faq24hhotlineItems}
                    ></Accordion>
                  </Box>
                )}
              </InnerLayout>
            </div>
          )}
        </div>
      </Layout>
    );
  }
}
