import "./Navigation.scss";

import { faSignOutAlt } from "@fortawesome/pro-light-svg-icons";
import {
  faBars,
  faLockOpenAlt,
  faUser,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Container, Hidden, Tooltip } from "@material-ui/core";
import React from "react";
import { NavLink } from "react-router-dom";

import { User } from "../../interfaces/user";
import Auth from "../../services/auth";
import Helper from "../../services/helper";
import UserManager from "../../services/manager/UserManager";

interface Props {
  items: any[];
  toggleDrawer: any;
}
interface State {
  user?: User;
}

export default class Navigation extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    if (Auth.isLoggedIn()) {
      const user = await UserManager.me();

      this.setState({
        user,
      });
    }
  }

  render() {
    return (
      <div className="navigation">
        <Container maxWidth="lg">
          <div className="nav-wrapper">
            <div className="nav-item logo">
              <NavLink to={"/"}>
                <img src="/assets/images/logo.svg" alt="" />
              </NavLink>
            </div>

            <Hidden mdDown>
              <div className="nav-item nav-item-list">
                <nav>
                  {this.props.items.map((item, index) => {
                    if (Auth.isLoggedIn() && item.title === "Registrierung")
                      return null;
                    return (
                      <div key={"nav-" + index}>
                        {!Auth.isLoggedIn() && (
                          <NavLink activeClassName="active" to={item.link}>
                            {item.title}
                          </NavLink>
                        )}

                        {Auth.isLoggedIn() &&
                          this.state &&
                          this.state.user &&
                          this.state.user.userInfo &&
                          this.state.user.userInfo._aditoBasicContractType &&
                          Helper.canViewPage(
                            true,
                            item.title,
                            this.state.user.userInfo._aditoBasicContractType
                          ) === true && (
                            <NavLink activeClassName="active" to={item.link}>
                              {item.title}
                            </NavLink>
                          )}

                        {Auth.isLoggedIn() &&
                          this.state &&
                          this.state.user &&
                          this.state.user.userInfo &&
                          this.state.user.userInfo._aditoBasicContractType &&
                          Helper.canViewPage(
                            true,
                            item.title,
                            this.state.user.userInfo._aditoBasicContractType
                          ) === false && (
                            <Tooltip
                              arrow
                              title={
                                <span>
                                  Leider ist diese Funktion noch nicht für Sie
                                  freigeschaltet.
                                  <br />
                                  Bitte wenden Sie sich an Ihre/n Bankberater/in.
                                </span>
                              }
                            >
                              <span className="disabledNavigationItem">
                                {item.title}
                              </span>
                            </Tooltip>
                          )}
                      </div>
                    );
                  })}

                  {!Auth.isLoggedIn() && (
                    <NavLink to={"/login"} activeClassName="">
                      <Button color="primary" variant="contained">
                        <FontAwesomeIcon
                          icon={faLockOpenAlt}
                          style={{ marginRight: 10 }}
                        />{" "}
                        Login Vorteilswelt
                      </Button>
                    </NavLink>
                  )}

                  {!!Auth.isLoggedIn() && (
                    <div>
                      <NavLink
                        to={"/profil"}
                        className="profile"
                        activeClassName=""
                      >
                        <Button color="secondary" variant="contained">
                          <FontAwesomeIcon
                            icon={faUser}
                            style={{ marginRight: 10 }}
                          />{" "}
                          Profil
                        </Button>
                      </NavLink>

                      <NavLink
                        to={"/abmelden"}
                        className="logout"
                        activeClassName=""
                      >
                        <Button color="primary" variant="contained">
                          <FontAwesomeIcon
                            icon={faSignOutAlt}
                            style={{ marginRight: 10 }}
                          />{" "}
                          Logout
                        </Button>
                      </NavLink>
                    </div>
                  )}
                </nav>
              </div>
            </Hidden>

            <div
              style={{ marginLeft: 30, display: "flex", alignItems: "center" }}
            >
              <div className="nav-item">
                <div className="burger">
                  <Button onClick={() => this.props.toggleDrawer()}>
                    <FontAwesomeIcon icon={faBars} />
                  </Button>
                </div>
              </div>

              <Hidden lgUp>
                <div className="nav-item">
                  {!Auth.isLoggedIn() && (
                    <NavLink to={"/login"} activeClassName="">
                      <Button
                        color="primary"
                        variant="contained"
                        className="mobileLogin"
                      >
                        <FontAwesomeIcon icon={faLockOpenAlt} />
                      </Button>
                    </NavLink>
                  )}

                  {!!Auth.isLoggedIn() && (
                    <div>
                      <NavLink
                        to={"/profil"}
                        className="profile"
                        activeClassName=""
                      >
                        <Button color="primary" variant="contained">
                          <FontAwesomeIcon icon={faUser} />
                        </Button>
                      </NavLink>

                      <NavLink to={"/abmelden"} className="logout">
                        <Button color="secondary" variant="contained">
                          <FontAwesomeIcon icon={faSignOutAlt} />
                        </Button>
                      </NavLink>
                    </div>
                  )}
                </div>
              </Hidden>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}
