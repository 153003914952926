import './ImageSlider.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/swiper.scss';

import { Button } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import Headline from '../Headline/Headline';
import ServiceTile from '../ServiceTile/ServiceTile';

import { useState, useLayoutEffect } from 'react';
function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

interface Props {
  style?: any;
  images: {
    src: string;
    contentBox?: {
      aboveHeadline?: string;
      headline?: string;
      subline?: string;
      button?: {
        label: string;
        link?: string;
        onClick?: () => void;
      };
      content?: string;
      stoerer?: {
        link?: string;
        text: string;
      };
    };
    showServiceTile?: boolean;
    tel?: string;
  }[];
}

function ImageSlider(props: Props) {
  const { images } = props;

  var now = new Date();
  var nicolausReleaseDate = new Date('2021-12-01T00:00:00');
  var nicolausOverDate = new Date('2021-12-05T23:59:00');

  if (images.length > 1) SwiperCore.use([Pagination]);
  SwiperCore.use([Autoplay]);

  const [width] = useWindowSize();
  return (
    <div style={{ ...props.style }}>
      <Swiper
        className="ImageSlider"
        onSlideChange={() => {}}
        onSwiper={(swiper) => {}}
        pagination={{ clickable: true }}
        loop={images.length > 1}
        allowTouchMove={images.length > 1}
        autoplay={{ delay: 5000 }}
        key={images.length}
        autoHeight={false}
      >
        {images.map((image, index) => (
          <SwiperSlide
            className={`${width > 1140 ? 'slide' : ''}`}
            style={
              width > 1140 ? { backgroundImage: `url('${image.src}')` } : {}
            }
            key={index}
          >
            {width < 1140 && image.src && (
              <img className="img-for-mobile" src={image.src} alt="" />
            )}
            {image.contentBox && (
              <div className="content-box">
                {image.contentBox.stoerer && (
                  <div
                    className={
                      nicolausReleaseDate <= now && now <= nicolausOverDate
                        ? 'nicolaus-stoerer-class'
                        : 'stoerer-class'
                    }
                  >
                    {image.contentBox.stoerer.link && (
                      <Link to={image.contentBox.stoerer.link}>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: image.contentBox.stoerer.text,
                          }}
                        ></span>
                      </Link>
                    )}
                    {!image.contentBox.stoerer.link && (
                      <span
                        className="only-text"
                        dangerouslySetInnerHTML={{
                          __html: image.contentBox.stoerer.text,
                        }}
                      ></span>
                    )}
                  </div>
                )}
                {image.contentBox.aboveHeadline && (
                  <Headline type="h4" color="secondary">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: image.contentBox.aboveHeadline,
                      }}
                    ></span>
                  </Headline>
                )}

                {image.contentBox.headline && (
                  <Headline type="h2">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: image.contentBox.headline,
                      }}
                    ></span>
                  </Headline>
                )}

                {image.contentBox.subline && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: image.contentBox.subline,
                    }}
                  ></p>
                )}

                {image.contentBox.content && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: image.contentBox.content,
                    }}
                  ></div>
                )}

                {image.contentBox.button &&
                  image.contentBox.button.link &&
                  !image.contentBox.button.link.includes('http') && (
                    <Link to={image.contentBox.button.link}>
                      <Button color="primary" variant="contained">
                        {image.contentBox.button.label}
                      </Button>
                    </Link>
                  )}

                {image.contentBox.button &&
                  image.contentBox.button.link &&
                  image.contentBox.button.link.includes('http') && (
                    <a href={image.contentBox.button.link} target="_blank">
                      <Button color="primary" variant="contained">
                        {image.contentBox.button.label}
                      </Button>
                    </a>
                  )}

                {image.contentBox &&
                  image.contentBox.button &&
                  !image.contentBox.button.link &&
                  image.contentBox.button.onClick && (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={image.contentBox.button.onClick}
                    >
                      {image.contentBox.button.label}
                    </Button>
                  )}
                {image.showServiceTile && (
                  <ServiceTile tel={image.tel}></ServiceTile>
                )}
              </div>
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default ImageSlider;
