import "./Security.scss";

import {
  faCreditCard,
  faDoorOpen,
  faKey,
  faMoneyBillWave,
} from "@fortawesome/pro-light-svg-icons";
import { Grid } from "@material-ui/core";
import React from "react";
import { Helmet } from "react-helmet";

import Box from "../../components/Box/Box";
import Headline from "../../components/Headline/Headline";
import ImageSlider from "../../components/ImageSlider/ImageSlider";
import InnerLayout from "../../components/InnerLayout/InnerLayout";
import { Layout } from "../../components/Layout/Layout";
import { Loading } from "../../components/Loading";
import ServiceTile from "../../components/ServiceTile/ServiceTile";
import ShortlinkBox from "../../components/ShortlinkBox/ShortlinkBox";
import { ContentElements } from "../../interfaces/ContentElements";
import { User } from "../../interfaces/user";
import Auth from "../../services/auth";
import Helper from "../../services/helper";
import CmsManager from "../../services/manager/CmsManager";
import StateManager from "../../services/manager/StateManager";
import UserManager from "../../services/manager/UserManager";

interface Props {
  location: any;
}

interface State {
  loading: boolean;
  content?: ContentElements;
  user?: User;
  matchingRoute: string;
}

export default class Security extends React.Component<Props, State> {
  private params: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      matchingRoute: "",
    };
  }

  getParams = () => {
    this.params = this.props;

    this.setState({
      matchingRoute: this.params.match.params.id,
    });
  };

  scrollToElement() {
    const element = document
      .getElementById(window.location.hash.replace("#", ""))
      ?.getBoundingClientRect();

    if (element) {
      window.scrollTo({
        top: element ? element.top + window.scrollY : 0,
        left: 0,
        behavior: "smooth",
      });
    }
  }

  async componentDidMount() {
    StateManager.resetProductsFilter();
    StateManager.resetShopFilter();

    this.getParams();

    if (Auth.isLoggedIn()) {
      const user = await UserManager.me();

      this.setState({
        user,
      });
    }

    const content = await CmsManager.getPageContentsCleaned("sicherheit");

    this.setState({
      content,
      loading: false,
    });
  }

  render() {
    return (
      <Layout>
        <div className="Security">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Sicherheitsleistungen - OLBplus Vorteilsportal</title>
          </Helmet>
          {this.state.loading && (
            <InnerLayout>
              <Loading />
            </InnerLayout>
          )}

          {!this.state.loading && this.state.content && (
            <div>
              <InnerLayout>
                <div className="onlyMobile">
                  <Box>
                    {this.state.content.mitmehrsicherheitdurchslebenTitle && (
                      <div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "Space-between",
                            alignItems: "center",
                            flexWrap: "wrap",
                            flexGrow: 1,
                          }}
                        >
                          <Headline type="h1">
                            {
                              this.state.content
                                .mitmehrsicherheitdurchslebenTitle
                            }
                          </Headline>
                        </div>

                        {this.state.content.mitmehrsicherheitdurchsleben && (
                          <div style={{ width: "80%" }}>
                            <Headline
                              type="h2"
                              color="secondary"
                              styling="thin"
                            >
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: this.state.content
                                    .mitmehrsicherheitdurchsleben as string,
                                }}
                              ></p>
                            </Headline>
                          </div>
                        )}

                        <ServiceTile tel="0521 7000 720"></ServiceTile>
                      </div>
                    )}
                  </Box>
                </div>

                {this.state.content.sicherheitimagesliderTitle && (
                  <ImageSlider
                    images={[
                      {
                        src: "/assets/images/heroes/Sicherheit.jpg",
                        contentBox: {
                          headline:
                            (this.state.content
                              .sicherheitimagesliderSubtitle as string) || "",
                          content: this.state.content
                            .sicherheitimageslider as string,
                        },
                      },
                    ]}
                  />
                )}

                <Box>
                  <div className="notMobile">
                    {this.state.content.mitmehrsicherheitdurchslebenTitle && (
                      <div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "Space-between",
                            alignItems: "center",
                            flexGrow: 1,
                          }}
                        >
                          <Headline type="h1">
                            {
                              this.state.content
                                .mitmehrsicherheitdurchslebenTitle
                            }
                          </Headline>

                          <ServiceTile tel="0521 7000 720"></ServiceTile>
                        </div>

                        {this.state.content.mitmehrsicherheitdurchsleben && (
                          <div style={{ width: "80%" }}>
                            <Headline
                              type="h2"
                              color="secondary"
                              styling="thin"
                            >
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: this.state.content
                                    .mitmehrsicherheitdurchsleben as string,
                                }}
                              ></p>
                            </Headline>
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  <Grid container spacing={2}>
                    <Grid xs={12} sm={6} md={3} item>
                      <ShortlinkBox
                        icon={faCreditCard}
                        title="Karten- und Dokumentenschutz"
                        link="#kartenunddokumentenschutz"
                        onClick={() => this.scrollToElement()}
                        buttonText="Mehr erfahren"
                      >
                        <p>
                          Ihr Portmonee ist verloren gegangen? Sie benötigen
                          Ihre wichtigsten Papiere und können nicht darauf
                          zugreifen?
                        </p>
                      </ShortlinkBox>
                    </Grid>

                    <Grid xs={12} sm={6} md={3} item>
                      <ShortlinkBox
                        icon={faKey}
                        title="Schlüsselfundservice"
                        link="#schlusselfundservice"
                        onClick={() => this.scrollToElement()}
                        buttonText="Mehr erfahren"
                      >
                        <p>
                          Wir bringen Ihren verlorenen Schlüssel zu Ihnen
                          zurück! Wie das geht? Ganz einfach:
                        </p>
                      </ShortlinkBox>
                    </Grid>

                    <Grid xs={12} sm={6} md={3} item>
                      <ShortlinkBox
                        icon={faMoneyBillWave}
                        title="Notfallbargeld"
                        link="#notfallbargeld"
                        onClick={() => this.scrollToElement()}
                        buttonText="Mehr erfahren"
                      >
                        <p>
                          Brieftasche weg? Reisekasse futsch? Mit uns verlieren
                          Sie im Fall der Fälle nicht auch noch die Nerven.
                        </p>
                      </ShortlinkBox>
                    </Grid>

                    <Grid xs={12} sm={6} md={3} item>
                      <ShortlinkBox
                        icon={faDoorOpen}
                        title="Türöffnungsservice"
                        link="#turoffnungsservice"
                        onClick={() => this.scrollToElement()}
                        buttonText="Mehr erfahren"
                      >
                        <p>
                          Die Tür ist ins Schloss gefallen und der Schlüssel
                          liegt in der Wohnung?
                        </p>
                      </ShortlinkBox>
                    </Grid>
                  </Grid>
                </Box>

                {Object.keys(this.state.content).map((item: string, index) => {
                  if (
                    this.state.content &&
                    item !== "pageTitle" &&
                    item !== "pageDescription" &&
                    !item.startsWith("sicherheitimageslider") &&
                    !item.startsWith("mitmehrsicherheitdurchsleben") &&
                    item.endsWith("Title")
                  ) {
                    return (
                      <Box
                        alternativeColor
                        style={{
                          marginBottom: 16,
                        }}
                        id={item.slice(0, -5)}
                        key={index}
                      >
                        {this.state.user &&
                          this.state.user?.userInfo &&
                          this.state.user?.userInfo._aditoBasicContractType &&
                          !Helper.hasSecurityService(
                            item.slice(0, -5),
                            this.state.user?.userInfo._aditoBasicContractType
                          ) && (
                            <Headline type="h4" color="primary">
                              Leider ist diese Funktion noch nicht für Sie
                              freigeschaltet. Bitte wenden Sie sich an Ihre/n
                              Berater/in.
                            </Headline>
                          )}

                        <div
                          style={{ width: "80%" }}
                          className={
                            this.state.user &&
                            this.state.user?.userInfo &&
                            this.state.user?.userInfo._aditoBasicContractType &&
                            !Helper.hasSecurityService(
                              item.slice(0, -5),
                              this.state.user?.userInfo._aditoBasicContractType
                            )
                              ? "disabled"
                              : ""
                          }
                        >
                          <Headline type="h2">
                            {this.state.content[item]}
                          </Headline>

                          <p
                            dangerouslySetInnerHTML={{
                              __html: this.state.content[
                                item.slice(0, -5)
                              ] as string,
                            }}
                          ></p>
                        </div>
                      </Box>
                    );
                  } else {
                    return "";
                  }
                })}
              </InnerLayout>
            </div>
          )}
        </div>
      </Layout>
    );
  }
}
