import './ServiceTile.scss';

import React from 'react';

export default ({ tel, timePeriods }: any) => {
  timePeriods = timePeriods || [
    { title: "Werktags:", period: "8 - 20 h" },
    { title: "Samstags:", period: "8 - 14 h" },
  ];

  tel = tel || "0521 7000 720";

  return (
    <div className="ServiceTileWrapper">
      <div className="ServiceTile">
        <div className="TimePeriods">
          {timePeriods.map(
            (item: { title: string; period: string }, key: number) => (
              <p style={{ margin: 0 }} key={key}>
                {item.title} {item.period}
              </p>
            )
          )}
        </div>

        <div>
          <a href={"tel:" + tel.replace(/\s|-/g, "")} className="tel">
            <img
              src="/assets/images/icons/service.svg"
              alt=""
              style={{
                marginRight: 10,
                marginTop: 8,
                marginBottom: -8,
                display: "inline-block",
              }}
            />
            {tel}
          </a>
          <br />
          <span className="mehrwerkHint">Mehrwerk GmbH</span>
        </div>
      </div>
    </div>
  );
};
