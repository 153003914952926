import './Dashboard.scss';

import {
  faAngleRight,
  faFileSignature,
  faLongArrowRight,
  faPlaneArrival,
  faUserCog,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, TextField } from '@material-ui/core';
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import Box from '../../components/Box/Box';
import DashboardBox from '../../components/DashboardBox/DashboardBox';
import Headline from '../../components/Headline/Headline';
import ImageSlider from '../../components/ImageSlider/ImageSlider';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import { Layout } from '../../components/Layout/Layout';
import { Loading } from '../../components/Loading';
import ShopTile from '../../components/ShopTile/ShopTile';
import ShortlinkBox from '../../components/ShortlinkBox/ShortlinkBox';
import { CashoutProfile } from '../../interfaces/cashoutProfile';
import Shop from '../../interfaces/shop';
import { User } from '../../interfaces/user';
import Auth from '../../services/auth';
import Helper from '../../services/helper';
import CashbackManager from '../../services/manager/CashbackManager';
import StateManager from '../../services/manager/StateManager';
import UserManager from '../../services/manager/UserManager';
import VoucherManager from '../../services/manager/VoucherManager';
import CmsManager from '../../services/manager/CmsManager';

interface Props {
  history: any;
}

interface State {
  confirmedCashback?: number;
  pendingCashback?: number;
  cashoutProfile?: CashoutProfile;
  openComplaints?: number;
  favoriteShops: Shop[];
  loading: boolean;
  user?: User;
  voucher: any;
  unsubscriptionContent: any;
}

export default class Dashboard extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      favoriteShops: [],
      loading: true,
      voucher: '',
      unsubscriptionContent: {},
    };

    this.handleFavoriteChange = this.handleFavoriteChange.bind(this);
    this.submitVoucher = this.submitVoucher.bind(this);
    this.handleVoucherChange = this.handleVoucherChange.bind(this);
    this.fetchCashbackData = this.fetchCashbackData.bind(this);
  }

  fetchCashbackData = async () => {
    const [
      { confirmed: confirmedCashback, open: pendingCashback },
      cashoutProfile,
      openComplaints,
      rawFavoriteShops,
    ] = await Promise.all([
      CashbackManager.getCashbackAmountSums(['confirmed', 'open']),
      CashbackManager.getCashoutProfile(),
      CashbackManager.getNumberOfComplaints(),
      CashbackManager.findFavoriteShops(),
    ]);

    const favoriteShops = Array.isArray(rawFavoriteShops)
      ? rawFavoriteShops
      : [];

    this.setState({
      confirmedCashback,
      pendingCashback,
      cashoutProfile,
      openComplaints,
      favoriteShops,
    });
  };

  async componentDidMount() {
    this.setState({
      loading: false,
    });

    this.fetchCashbackData();

    StateManager.resetProductsFilter();
    StateManager.resetShopFilter();

    const [user, unsubscriptionContent] = await Promise.all([
      UserManager.me(),
      CmsManager.getPageContentsCleaned('profile'),
    ]);

    this.setState({
      user,
      unsubscriptionContent,
      loading: false,
    });
  }

  async getUser() {
    const user = await UserManager.me();

    this.setState({
      user,
    });
  }

  handleFavoriteChange(shop: Shop) {
    return async (isFavorite: boolean) => {
      // Update shop
      if (!isFavorite) {
        await CashbackManager.removeShopFavorite(shop.id);
      } else {
        await CashbackManager.setShopFavorite(shop.id);
      }

      // Update view
      const shops = this.state.favoriteShops;

      shops.forEach((s: Shop) => {
        if (s.id === shop.id) {
          s.isFavorite = isFavorite;
        }
      });

      this.setState({
        favoriteShops: shops,
      });
    };
  }

  getBrowserName() {
    if (
      /(?!Chrom.*OPR)Chrom(?:e|ium)\/([0-9.]+)(:?\s|$)/.exec(
        navigator.userAgent
      )
    )
      return 'chrome';

    if (/Firefox\/([0-9.]+)(?:\s|$)/.exec(navigator.userAgent))
      return 'firefox';

    return '';
  }

  handleNewsletter(subscription: string) {
    const MySwal = withReactContent(Swal);

    if (subscription === 'subscribe') {
      MySwal.fire({
        icon: 'question',
        title: 'Newsletteranmeldung',
        html: `Ich möchte künftig den OLBplus Newsletter per E-Mail erhalten und über aktuelle Angebote, 
              Schnäppchen, Gutscheine und Aktionen informiert werden. Diese Einwilligung kann jederzeit 
              am Ende jeder E-Mail widerrufen werden.`,
        confirmButtonText: 'Anmelden',
        cancelButtonText: 'Schließen',
        showCancelButton: true,
        focusConfirm: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const registered = await UserManager.registerNewsletter(3);

          if (registered) {
            MySwal.fire({
              icon: 'success',
              title: 'Vielen Dank',
              confirmButtonText: 'Schließen',
              timer: 5000,
              timerProgressBar: true,
              focusConfirm: false,
            });
          }

          this.getUser();
        }
      });
    } else if (subscription === 'unsubscribe') {
      MySwal.fire({
        icon: 'question',
        title: 'Newsletterabmeldung',
        html: `Ich möchte den OLBplus-Newsletter abbestellen und in Zukunft nicht mehr erhalten. 
          Sie können sich jederzeit abmelden, indem Sie auf Ihrer Profilseite auf die Schaltfläche "Anmelden" klicken.`,
        confirmButtonText: 'Abmelden',
        cancelButtonText: 'Schließen',
        showCancelButton: true,
        focusConfirm: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const unsubscribe = await UserManager.registerNewsletter(4);

            if (unsubscribe?.userInfo?.newsletter === '4') {
              this.props.history.push('/newsletter-abmeldung');
            } else {
              MySwal.fire({
                icon: 'error',
                title: 'Fehler',
                html: `Ein Fehler ist aufgetreten .Bitte versuchen Sie es später noch einmal.`,
                confirmButtonText: 'Ok',
                focusConfirm: false,
              });
            }
          } catch (err) {
            console.log('error in unsubscription from newsletter', err);
            MySwal.fire({
              icon: 'error',
              title: 'Fehler',
              html: `Ein Fehler ist aufgetreten. Bitte versuchen Sie es später noch einmal.`,
              confirmButtonText: 'Ok',
              focusConfirm: false,
            });
          }
        }
      });
    }
  }

  handleVoucherChange = (event: any) => {
    this.setState({
      voucher: event.target.value,
    });
  };

  async submitVoucher() {
    const LoginSwal = withReactContent(Swal);

    const response = await VoucherManager.submitVoucher(this.state.voucher);

    if (response.code === 200) {
      LoginSwal.fire({
        title: 'Erfolgreich',
        text: response.message,
        icon: 'success',
        confirmButtonText: 'OK',
      });
    } else if (response.code === 400) {
      LoginSwal.fire({
        title: 'Fehlgeschlagen',
        text:
          'Leider wurden bereits alle Gutscheine eingelöst. Die Aktion ist beendet',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    } else {
      LoginSwal.fire({
        title: 'Fehlgeschlagen',
        text:
          'Leider wurden bereits alle Gutscheine eingelöst. Die Aktion ist beendet',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
    this.setState({ voucher: '' });
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Dashboard - OLBplus Vorteilsportal</title>
        </Helmet>
        <div className="Dashboard">
          {this.state.loading && (
            <InnerLayout>
              <Loading />
            </InnerLayout>
          )}

          {!this.state.loading && (
            <InnerLayout>
              <Box>
                <Headline type="h1">
                  Hallo{' '}
                  {`${
                    Auth.getTokenInfo().userInfo.salutation === 'Frau'
                      ? 'Frau'
                      : 'Herr'
                  } ${Auth.getTokenInfo().userInfo.lastName}`}
                  ,
                </Headline>

                <div style={{ width: '80%' }}>
                  <Headline type="h2" color="secondary" styling="thin">
                    hier finden Sie sämtliche Mehrwertleistungen und Services
                    Ihres Kontos rund um die Uhr. Shoppen Sie zum Beispiel mit
                    exklusiven Rabatten in mehr als 1.300 Onlineshops, buchen
                    Sie Reisen zu Schnäppchenpreisen oder laden Sie sich
                    wichtige Formulare herunter.
                  </Headline>
                </div>
              </Box>
              <Box style={{ paddingTop: 0 }}>
                <Headline type="h3">
                  <span style={{ fontWeight: 600 }}>
                    Ihre Vorteilsnummer: {Auth.getFKN()}
                  </span>
                </Headline>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <DashboardBox
                      title="Cashback Guthaben"
                      link="/transaktionen/uebersicht"
                      icon={faAngleRight}
                    >
                      <div className="dataWrapper">
                        <div className="dataRow">
                          <div className="dataCell">
                            <p>bestätigt</p>
                          </div>

                          <div className="dataCell">
                            <p style={{ fontWeight: 'bold' }}>
                              {Helper.formatNumber(
                                this.state.confirmedCashback || 0,
                                2
                              )}{' '}
                              EUR
                            </p>
                          </div>
                        </div>

                        <div className="dataRow">
                          <div className="dataCell">
                            <p>offen</p>
                          </div>

                          <div className="dataCell">
                            <p style={{ fontWeight: 'bold' }}>
                              {Helper.formatNumber(
                                this.state.pendingCashback || 0,
                                2
                              )}{' '}
                              EUR
                            </p>
                          </div>
                        </div>
                      </div>
                    </DashboardBox>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <DashboardBox
                      title="Auszahlungen"
                      link="/auszahlungen"
                      icon={faAngleRight}
                    >
                      <div className="dataWrapper">
                        <div className="dataRow">
                          <div className="dataCell">
                            <p>ausgezahlt</p>
                          </div>

                          <div className="dataCell">
                            <p style={{ fontWeight: 'bold' }}>
                              {(this.state.cashoutProfile &&
                                Helper.formatNumber(
                                  this.state.cashoutProfile.paidOutCash +
                                    this.state.cashoutProfile
                                      .externalPaidOutCash,
                                  2
                                )) ||
                                0}{' '}
                              EUR
                            </p>
                          </div>
                        </div>
                      </div>
                    </DashboardBox>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <DashboardBox
                      title="Nachbuchungsanfragen"
                      link="/nachbuchungsanfragen"
                      icon={faAngleRight}
                    >
                      <div className="dataWrapper">
                        <div className="dataRow">
                          <div className="dataCell">
                            <p>offene Anfragen</p>
                          </div>

                          <div className="dataCell">
                            <p style={{ fontWeight: 'bold' }}>
                              {this.state.openComplaints || 0}
                            </p>
                          </div>
                        </div>
                      </div>
                    </DashboardBox>
                  </Grid>
                </Grid>
              </Box>

              {this.state.user &&
                (!this.state.user.userInfo.newsletter ||
                  this.state.user.userInfo.newsletter === '4') && (
                  <ImageSlider
                    style={{ marginTop: '30px' }}
                    images={[
                      {
                        src: '/assets/images/heroes/olbNewsletter.jpg',
                        contentBox: {
                          aboveHeadline: 'OLBplus Newsletter',
                          headline:
                            'Für den Newsletter anmelden & attraktive Vorteile sichern',
                          subline: `<ul>
                          <li>Exklusive Aktionen und aktuelle Angebote</li>
                          <li>Schnäppchen und Gutscheine</li>
                          <li>Informationen zu Gewinnspielen und unseren Leistungen</li>
                        </ul>`,
                          button: {
                            label: 'Newsletter anmelden',
                            onClick: () => this.handleNewsletter('subscribe'),
                          },
                        },
                      },
                    ]}
                  />
                )}

              <Box>
                <Headline type="h2">Gutscheincode erhalten?</Headline>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <p>
                      Lösen Sie Ihren Gutscheincode für Ihren Einkauf unter
                      "Shops und Produkte" ein.
                    </p>
                    <Grid container spacing={4}>
                      <Grid item xs={12} md={9}>
                        <TextField
                          onChange={(event: any) =>
                            this.handleVoucherChange(event)
                          }
                          placeholder="Gutscheincode"
                          value={this.state.voucher}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <div className="buttonWrapper">
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={this.submitVoucher}
                            style={{ marginTop: '-18px' }}
                          >
                            <span>Jetzt Gutschein einlösen</span>
                            <FontAwesomeIcon icon={faLongArrowRight} />
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>

              {this.state.favoriteShops && this.state.favoriteShops.length > 0 && (
                <Grid container spacing={4} style={{ marginTop: '50px' }}>
                  <Grid item xs={12}>
                    <Box alternativeColor>
                      <Headline type="h2">Ihre Cashback-Favoriten</Headline>

                      <Grid container spacing={2}>
                        {this.state.favoriteShops.map((shop: Shop, key) => (
                          <Grid item xs={6} md={3} key={key}>
                            <ShopTile
                              image={shop.logo}
                              buttonLabel="Zum Shop"
                              morePath={`/shop/${shop.id}`}
                              buttonLink={shop.link}
                              isFavorite={shop.isFavorite}
                              onFavoriteChange={this.handleFavoriteChange(shop)}
                              history={this.props.history}
                              shopId={shop.id}
                            >
                              {shop.maximumCashback && (
                                <Fragment>
                                  Bis zu{' '}
                                  <span>
                                    {Helper.formatNumber(
                                      shop.maximumCashback,
                                      2
                                    )}{' '}
                                    {shop.maximumCashbackType}
                                  </span>{' '}
                                  bei {shop.name}
                                </Fragment>
                              )}
                            </ShopTile>
                          </Grid>
                        ))}
                      </Grid>

                      <div
                        style={{
                          textAlign: 'center',
                          padding: '1em',
                          paddingTop: '3em',
                        }}
                      >
                        <Link
                          to={{
                            pathname: '/cashback/shops',
                            state: {
                              filterState: {
                                direction: 'desc',
                                filters: ['favorites'],
                                favorites: true,
                                orderBy: 'popularity',
                                page: 1,
                              },
                            },
                          }}
                        >
                          Alle Cashback-Favoriten anzeigen
                          <FontAwesomeIcon
                            icon={faAngleRight}
                            style={{
                              marginLeft: 10,
                            }}
                          />
                        </Link>
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              )}
              <Box>
                <Grid container spacing={2} className="shortlinks">
                  <div className="flex-holder">
                    <ShortlinkBox
                      icon="OlbIconCashbackRadar"
                      title="OLBplus Cashbackradar"
                      link="/cashbackradar"
                      buttonText="Jetzt herunterladen"
                      targetBlank
                    ></ShortlinkBox>

                    <ShortlinkBox
                      icon={faPlaneArrival}
                      onClick={(e: any) =>
                        Helper.handleClickoutHint(
                          {
                            type: 'travelInsurance',
                            url:
                              'https://www.olb.de/privatkunden/konten-und-karten/bezahlen-mit-karten/mastercard-gold-visa-card-gold#e8dc012b-5417-4ab2-b6d8-967b2e199d19',
                          },
                          e,
                          this.props.history
                        )
                      }
                      title="Reiseversicherung"
                      link="reiseversicherung"
                      buttonText="Mehr erfahren"
                    ></ShortlinkBox>

                    <ShortlinkBox
                      icon={faFileSignature}
                      title="Dokumente & Formulare"
                      link="/dokumente-und-formulare"
                      buttonText="Jetzt herunterladen"
                    ></ShortlinkBox>

                    <ShortlinkBox
                      icon={faUserCog}
                      title="Ihre Daten"
                      link="/ihre-daten"
                      buttonText="Daten anpassen"
                    ></ShortlinkBox>
                  </div>
                </Grid>
              </Box>

              {this.state.user?.userInfo?.newsletter === '1' && (
                <Box alternativeColor>
                  <Box>
                    <h1>
                      {
                        this.state.unsubscriptionContent
                          ?.newsletterunsubscriptionSubtitle
                      }
                    </h1>

                    <Grid item xs={12}>
                      <div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: this.state.unsubscriptionContent
                              .newsletterunsubscription as string,
                          }}
                        ></div>
                      </div>
                    </Grid>

                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => this.handleNewsletter('unsubscribe')}
                    >
                      Abmelden
                    </Button>
                  </Box>
                </Box>
              )}
            </InnerLayout>
          )}
        </div>
      </Layout>
    );
  }
}
