import './Home.scss';

import {
  faAngleRight,
  faPlaneDeparture,
  faUserAstronaut,
  faSolarSystem,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@material-ui/core';
import * as React from 'react';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Link, Redirect } from 'react-router-dom';

import Box from '../../components/Box/Box';
import Headline from '../../components/Headline/Headline';
import ImageSlider from '../../components/ImageSlider/ImageSlider';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import { Layout } from '../../components/Layout/Layout';
import { Loading } from '../../components/Loading';
import ShopTile from '../../components/ShopTile/ShopTile';
import ShortlinkBox from '../../components/ShortlinkBox/ShortlinkBox';
import SpecialTile from '../../components/SpecialTile/SpecialTile';
import { ContentElements } from '../../interfaces/ContentElements';
import Shop from '../../interfaces/shop';
import Special from '../../interfaces/special';
import { Alert } from '../../services/alert';
import Config from '../../services/config';
import Helper from '../../services/helper';
import CashbackManager from '../../services/manager/CashbackManager';
import CmsManager from '../../services/manager/CmsManager';
import NewsletterManager from '../../services/manager/NewsletterManager';
import StateManager from '../../services/manager/StateManager';

interface Props {
  history: any;
}

interface State {
  cashbackShops: Shop[];
  specials: Special[];
  loading: boolean;
  redirectToSearch: boolean;
  query: string;
  newsletterEmail: string;
  content?: ContentElements;
  fashionId: string;
  nicolausIsReleased: boolean;
}

export class Home extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      cashbackShops: [],
      specials: [],
      loading: true,
      redirectToSearch: false,
      query: '',
      newsletterEmail: '',
      fashionId: '',
      nicolausIsReleased: false,
    };

    this.handleFavoriteChange = this.handleFavoriteChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChangeQuery = this.handleChangeQuery.bind(this);
    this.handleChangeNewsletterEmail = this.handleChangeNewsletterEmail.bind(
      this
    );
    this.handleSubscribeNewsletter = this.handleSubscribeNewsletter.bind(this);
    this.fetchCashbackData = this.fetchCashbackData.bind(this);
  }

  handleFavoriteChange(shop: Shop) {
    return async (isFavorite: boolean) => {
      // Update shop
      if (!isFavorite) {
        await CashbackManager.removeShopFavorite(shop.id);
      } else {
        await CashbackManager.setShopFavorite(shop.id);
      }

      // Update view
      const shops = this.state.cashbackShops;

      shops.forEach((s: Shop) => {
        if (s.id === shop.id) {
          s.isFavorite = isFavorite;
        }
      });

      this.setState({
        cashbackShops: shops,
      });
    };
  }

  fetchCashbackData = async () => {
    const cashbackShops = await CashbackManager.findNewestShops(1, 4);
    const specials = await CashbackManager.findSpecials();

    if (typeof cashbackShops.items === 'object')
      cashbackShops.items = Object.values(cashbackShops.items);

    this.setState({
      cashbackShops: cashbackShops.items,
      specials: specials.slice(0, 2),
    });
  };

  async componentDidMount() {
    this.setState({
      loading: false,
    });

    this.fetchCashbackData();

    StateManager.resetProductsFilter();
    StateManager.resetShopFilter();

    const fashionId = Config.getFashionCategoryId();

    const content = await CmsManager.getPageContentsCleaned('startseite');

    this.setState({
      loading: false,
      content,
      fashionId,
    });
  }

  handleSearch() {
    this.setState({
      redirectToSearch: true,
    });
  }

  handleChangeQuery(query: string) {
    this.setState({
      query,
    });
  }

  handleChangeNewsletterEmail(e: any) {
    this.setState({
      newsletterEmail: e.target.value,
    });
  }

  NicolausIsReleased() {}

  async handleSubscribeNewsletter() {
    if (this.state.newsletterEmail.trim() === '') return;

    const response = await NewsletterManager.subscribe(
      this.state.newsletterEmail
    );

    if (response) {
      Alert.success(
        'Newsletter',
        'Vielen Dank für Ihre Anmeldung zum Newsletter!'
      );
    } else {
      Alert.error(
        'Newsletter',
        'Bitte überprüfen Sie die angegebene E-Mail Adresse.'
      );
    }

    this.setState({
      newsletterEmail: '',
    });
  }

  render() {
    if (this.state.redirectToSearch)
      return <Redirect to={`/shops-und-produkte?q=${this.state.query}`} />;

    return (
      <Layout>
        <div className="Home">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Home - OLBplus Vorteilsportal</title>
            <meta
              name="title"
              content="Das OLBplus Vorteilsportal: Ihr Bonus in Ihrem Girokonto der OLB"
            />
            <meta
              name="description"
              content="Nutzen Sie exklusive Vorteile und Services mit dem neuen Plus der OLB Girokonten und gestalten Sie Ihr Leben günstiger und sicherer."
            />
          </Helmet>

          {this.state.loading && (
            <InnerLayout>
              <Loading />
            </InnerLayout>
          )}

          {!this.state.loading && (
            <InnerLayout>
              {this.state.content && (
                <Grid className="onlyMobile">
                  <Box>
                    <Headline type="h1">
                      {this.state.content.pageTitle}
                    </Headline>

                    <p>Eine Leistung der Mehrwerk GmbH</p>

                    <Headline type="h2" color="secondary" styling="thin">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: this.state.content.pageDescription as string,
                        }}
                      ></p>
                    </Headline>
                  </Box>
                </Grid>
              )}
              {this.state.nicolausIsReleased === true && (
                <ImageSlider
                  images={[
                    {
                      src: '/assets/images/heroes/Reise.jpg',
                      contentBox: {
                        stoerer: {
                          text:
                            '<img src="/assets/images/nikolausstiefel.gif" />',
                          link: '/registrierung',
                        },
                        aboveHeadline: 'OLBplus Reise',
                        headline: 'Auch reif für den Urlaub?',
                        subline:
                          'Entdecken Sie jetzt den OLBplus - Reisebuchungsservice und erhalten auf Ihre kommende Reise bis zu 7 % Reiserückvergütung im Konto XL.',
                        button: { label: 'Weiter', link: '/reise' },
                      },
                    },
                    {
                      src: '/assets/images/heroes/Online.jpg',
                      contentBox: {
                        stoerer: {
                          text:
                            '<img src="/assets/images/nikolausstiefel.gif" />',
                          link: '/registrierung',
                        },
                        aboveHeadline: 'OLBplus Cashback',
                        headline: 'Onlinecashback',
                        subline:
                          'Shoppen Sie in über 1.300 Onlineshops oder finden Sie Ihr Lieblingsprodukt.',
                        button: { label: 'Weiter', link: '/cashback/shops' },
                      },
                    },
                    {
                      src: '/assets/images/heroes/Tickets.jpg',
                      contentBox: {
                        stoerer: {
                          text:
                            '<img src="/assets/images/nikolausstiefel.gif" />',
                          link: '/registrierung',
                        },
                        aboveHeadline: 'OLBplus Ticket',
                        headline: 'Tickets',
                        subline:
                          'Ob Konzerte, Comedyshows, Musicals, Sportevents oder Theater: Bestellen Sie die Tickets Ihrer Wahl ganz einfach online.',
                        button: { label: 'Weiter', link: '/tickets' },
                      },
                    },
                  ]}
                />
              )}
              {this.state.nicolausIsReleased === false && (
                <ImageSlider
                  images={[
                    {
                      src: '/assets/images/heroes/Reise.jpg',
                      contentBox: {
                        stoerer: {
                          text:
                            'Jetzt <span class="highlighted">hier</span> registrieren',
                          link: '/registrierung',
                        },
                        aboveHeadline: 'OLBplus Reise',
                        headline: 'Auch reif für den Urlaub?',
                        subline:
                          'Entdecken Sie jetzt den OLBplus - Reisebuchungsservice und erhalten auf Ihre kommende Reise bis zu 7 % Reiserückvergütung im Konto XL.',
                        button: { label: 'Weiter', link: '/reise' },
                      },
                    },
                    {
                      src: '/assets/images/heroes/Online.jpg',
                      contentBox: {
                        stoerer: {
                          text:
                            'Jetzt <span class="highlighted">hier</span> registrieren',
                          link: '/registrierung',
                        },
                        aboveHeadline: 'OLBplus Cashback',
                        headline: 'Onlinecashback',
                        subline:
                          'Shoppen Sie in über 1.300 Onlineshops oder finden Sie Ihr Lieblingsprodukt.',
                        button: { label: 'Weiter', link: '/cashback/shops' },
                      },
                    },
                    {
                      src: '/assets/images/heroes/Tickets.jpg',
                      contentBox: {
                        stoerer: {
                          text:
                            'Jetzt <span class="highlighted">hier</span> registrieren',
                          link: '/registrierung',
                        },
                        aboveHeadline: 'OLBplus Ticket',
                        headline: 'Tickets',
                        subline:
                          'Ob Konzerte, Comedyshows, Musicals, Sportevents oder Theater: Bestellen Sie die Tickets Ihrer Wahl ganz einfach online.',
                        button: { label: 'Weiter', link: '/tickets' },
                      },
                    },
                  ]}
                />
              )}
              {this.state.content &&
                this.state.content.pageTitle &&
                this.state.content.pageDescription && (
                  <Grid className="notMobile">
                    <Box>
                      <Headline type="h1">
                        {this.state.content.pageTitle}
                        <p
                          style={{
                            fontSize: 15,
                            marginTop: 20,
                            fontWeight: 100,
                            fontFamily: 'Arial, sans-serif',
                          }}
                        >
                          Eine Leistung der Mehrwerk GmbH
                        </p>
                      </Headline>

                      <Headline type="h2" color="secondary" styling="thin">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: this.state.content
                              .pageDescription as string,
                          }}
                        ></p>
                      </Headline>
                    </Box>
                  </Grid>
                )}
              <Box>
                <Grid container spacing={2}>
                  <Grid xs={12} sm={4} item>
                    <ShortlinkBox
                      icon="OlbIconCashbackRadar"
                      title="OLBplus Cashbackradar"
                      link="/cashbackradar"
                      buttonText="Mehr erfahren"
                    >
                      <p>
                        Jetzt Cashback sammeln und keine Rabatte mehr verpassen.
                      </p>
                    </ShortlinkBox>
                  </Grid>

                  <Grid xs={12} sm={4} item>
                    <ShortlinkBox
                      icon={faUserAstronaut}
                      title="OLBplus Ticket"
                      link="/tickets"
                      buttonText="Jetzt Tickets sichern"
                    >
                      <p>
                        Buchen Sie jetzt Tickets für Ihr Lieblingsevent und
                        schaffen Sie mehr Erinnerungen denn je.
                      </p>
                    </ShortlinkBox>
                  </Grid>

                  <Grid xs={12} sm={4} item>
                    <ShortlinkBox
                      icon={faPlaneDeparture}
                      link="/reise"
                      title="OLBplus Reise"
                      buttonText="Jetzt Reise buchen"
                    >
                      <p>
                        Buchen Sie Ihre Traumreise mit einer Auswahl von über
                        180 namhaften Veranstaltern.
                      </p>
                    </ShortlinkBox>
                  </Grid>
                </Grid>
              </Box>

              <Box style={{ marginBottom: 30 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6} className="crossLinkBox">
                    <img
                      src="/assets/images/heroes/surfer.jpg"
                      alt=""
                      style={{ width: '100%' }}
                    />

                    <Headline type="h2">Badehose schon gekauft?</Headline>

                    <p>Jetzt Cashback sichern für Ihre Sommermode.</p>

                    <Link
                      to={{
                        pathname: '/cashback/shops',
                        state: {
                          filterState: {
                            page: 1,
                            filter: {},
                            categoryFilter: this.state.fashionId,
                            orderBy: 'popularity',
                            direction: 'desc',
                          },
                        },
                      }}
                      className="twoColorLink moreLink"
                    >
                      <FontAwesomeIcon
                        icon={faAngleRight}
                        style={{
                          marginRight: 10,
                          color: '#ff8c00',
                        }}
                      />
                      Alle Fashion & Lifestyle Shops
                    </Link>
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <img
                      src="/assets/images/heroes/regenschirm.jpg"
                      alt=""
                      style={{ width: '100%' }}
                    />

                    <Headline type="h2">
                      Wir lassen Sie nicht im Regen stehen
                    </Headline>

                    <p>Weltweites Notfallbargeld innerhalb weniger Minuten.</p>

                    <Link
                      to="/sicherheit#notfallbargeld"
                      className="twoColorLink moreLink"
                    >
                      <FontAwesomeIcon
                        icon={faAngleRight}
                        style={{
                          marginRight: 10,
                          color: '#ff8c00',
                        }}
                      />
                      Mehr Informationen
                    </Link>
                  </Grid>
                </Grid>
              </Box>

              {(this.state.specials?.length > 0 ||
                this.state.cashbackShops?.length > 0) && (
                <Box alternativeColor>
                  <Grid container spacing={2}>
                    <Grid item xs={12} className="cashbackSpecials">
                      {this.state.specials?.length > 0 && (
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Headline type="h2">
                              Die neuesten Cashback-Highlights
                            </Headline>
                          </Grid>

                          {this.state.specials.map((special, key) => (
                            <Grid item xs={12} sm={6} key={key}>
                              <SpecialTile
                                image={special.image}
                                title={special.title}
                                subTitle={special.subTitle}
                                info={
                                  special.end
                                    ? `Nur bis zum ${Helper.formatDate(
                                        special.end
                                      )}`
                                    : undefined
                                }
                                shopId={special.id}
                                history={this.props.history}
                                buttonLabel="Zum Shop"
                                buttonLink={special.link}
                                priceActual={special.priceActual}
                                priceBefore={special.priceBefore}
                                specialText={special.text}
                              >
                                {special.text}
                              </SpecialTile>
                            </Grid>
                          ))}

                          <Grid
                            item
                            xs={12}
                            style={{
                              textAlign: 'center',
                              padding: '1em',
                              paddingTop: '3em',
                            }}
                          >
                            <Link to="/angebote" className="moreLink">
                              Alle Cashback-Highlights anzeigen
                              <FontAwesomeIcon
                                icon={faAngleRight}
                                style={{
                                  marginLeft: 10,
                                }}
                              />
                            </Link>
                          </Grid>
                        </Grid>
                      )}

                      {this.state.cashbackShops?.length > 0 && (
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Headline type="h2">
                              Die neusten Cashback-Shops
                            </Headline>
                          </Grid>

                          {this.state.cashbackShops.map((shop: Shop, key) => (
                            <Grid item xs={6} md={3} key={key}>
                              <ShopTile
                                image={shop.logo}
                                buttonLabel="Zum Shop"
                                morePath={`/shop/${shop.id}`}
                                buttonLink={shop.link}
                                isFavorite={shop.isFavorite}
                                onFavoriteChange={this.handleFavoriteChange(
                                  shop
                                )}
                                history={this.props.history}
                                shopId={shop.id}
                              >
                                {shop.maximumCashback && (
                                  <Fragment>
                                    Bis zu{' '}
                                    <span>
                                      {Helper.formatNumber(
                                        shop.maximumCashback,
                                        2
                                      )}{' '}
                                      {shop.maximumCashbackType}
                                    </span>{' '}
                                    bei {shop.name}
                                  </Fragment>
                                )}
                              </ShopTile>
                            </Grid>
                          ))}

                          <Grid
                            xs={12}
                            item
                            style={{
                              textAlign: 'center',
                              padding: '1em',
                              paddingTop: '3em',
                            }}
                          >
                            <Link to="/cashback/shops" className="moreLink">
                              Alle Cashback-Shops anzeigen
                              <FontAwesomeIcon
                                icon={faAngleRight}
                                style={{
                                  marginLeft: 10,
                                }}
                              />
                            </Link>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              )}
            </InnerLayout>
          )}
        </div>
      </Layout>
    );
  }
}
